.multiple-search-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
}

.download-all {
  background-color: #06274C;
  color: white;
  padding: 8px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

/* .download-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
} */
