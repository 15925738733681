.login-container {
  max-width: 400px;
  margin: 80px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  text-align: center;
}
.login-container h1 {
  color: #1A237E;
  margin-bottom: 20px;
}
.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.login-form label {
  font-weight: bold;
  color: #1A237E;
  text-align: left;
}
.login-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  background-color: #1A237E;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #0D47A1;
}
.message {
  color: red;
  margin: 10px 0;
}
