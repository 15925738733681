/* HomePage.css */

/* Main container for the home page */
.home-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

/* Search form styling */
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 200px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #06274C;  /* Dark blue from Claim Lion Law */
}

.search-form select,
.search-form input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Slimmer search button */
.search-form button {
  background-color: #06274C;
  color: #fff;
  border: none;
  padding: 6px 12px;  /* Reduced padding for a slimmer look */
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.search-form button:hover {
  background-color: #104080;
}

/* Client information section */
.client-info {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #444444;
}

/* Single table for client references (case reference and bank name) */
.references-table {
  margin-top: 20px;
}

.references-table table {
  width: 50%;
  border-collapse: collapse;
}

.references-table th,
.references-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
}

/* File results table */
.file-results {
  margin-top: 20px;
}

.file-results table {
  width: 90%;
  border-collapse: collapse;  
}

.file-results th,
.file-results td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  color: #444444;
  font-size: 14px;
}

/* Message text styling */
.message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .search-form {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .form-group {    
    flex: unset;
  }
}


.home-container {
  max-width: 1250px;
  margin: 0 auto; /* Center horizontally */
  padding: 20px;  
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.search-form {
  /* For example, a simple one-column layout on mobile,
     a row layout on desktop */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-submit {
  margin:auto;
}

.h4-heading {
  margin: 5px 0px;
}

.col1 {
  width: 15%;
}

.col2 {
  width: 75%;
}

.col3 {
  width: 10%;
  text-align: center !important;
}

.filters {
  display: flex;  
  gap: 20px;
  margin: 20px 0;
}

.filter {
  width: 300px;
}

.filter label {
  color: #444444;  
}

