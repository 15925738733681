/* src/styles/App.css */

/* Global reset-ish */
html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  overflow-x: hidden; /* Prevent horizontal scroll on mobile */
}

/* A reusable container if you want consistent width across pages */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Main app content spacing */
.app-content {
  padding: 20px 0;  /* some breathing room below the header */
}

/* Basic forms, tables, etc. you had previously */
form {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px; /* narrower forms look nicer */
}

form label {
  display: block;
  margin-bottom: 8px;
  color: #0d3b66;
  font-weight: bold;
}

form input, form select {  
  padding: 10px;  
  border: 1px solid #ddd;
  border-radius: 10px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 8px 8px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0a2a4d;
}

.results {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
}

.results h2 {
  color: #0d3b66;
}

.results table {
  width: 100%;
  border-collapse: collapse;
}

.results th, .results td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.results th {
  background-color: #0d3b66;
  color: white;
}

.results tr:nth-child(even) {
  background-color: #f2f2f2;
}

.results a {
  color: #0d3b66;
  text-decoration: none;
}

.results a:hover {
  text-decoration: underline;
}

.linkrow {
  width: 100px;
  text-align: center !important;
}


h2 {
    color: #444444;
}