.change-password-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  text-align: center;
}
.change-password-container h1 {
  color: #1A237E;
  margin-bottom: 20px;
}
.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.change-password-form label {
  font-weight: bold;
  color: #1A237E;
}
.change-password-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  padding: 10px 20px;
  background-color: #1A237E;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #0D47A1;
}
.success-message {
  color: green;
  margin: 10px 0;
}
