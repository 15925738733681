
.main-header {
  width: 100%;
  background-color: #007bff;
  color: #FFFFFF;
  padding: 20px 0; 
  box-sizing: border-box;
}

.header-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 50px;
  margin-right: 15px;
}

.app-name {
  font-size: 1.8rem;
  margin: 0;
}

.burger-icon {
  display: none; 
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  cursor: pointer;
}
.burger-icon div {
  width: 100%;
  height: 3px;
  background-color: #FFFFFF;
  border-radius: 2px;
}


.nav-menu {  
  position: fixed;
  top: 0;
  right: 0;            
  width: 250px;
  height: 100vh;
  background-color: #007bff;
  transform: translateX(100%); 
  transition: transform 0.3s ease-in-out;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 9999;       
  display: flex;       
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 20px;  
}

.nav-menu.open {
  transform: translateX(0);
}

.nav-menu a {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold;
  margin: 10px 0;
}
.nav-menu a:hover {
  text-decoration: underline;
}

.logout-button {
  background-color: transparent;
  border: none;
  color: #FFFFFF;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
}
.logout-button:hover {
  text-decoration: underline;
}

.app-name-mobile {
  display: none;
}

.app-name-full {
  display: block;
}

@media (min-width: 769px) {
  .header-center {
    margin-left: 300px;
  }
}

@media (max-width: 768px) {
  .burger-icon {
    display: flex; 
    margin-left: auto; 
  }
  
  .full-width-menu {
    display: none;
  }

  .app-name-full {
    display: none;
  }
  .app-name-mobile {
    display: block;
  }

  .header-left {
    display:block;
    margin: 0px;
  }
}

.app-name-mobile {
  margin: 0px;
}





.navbar {
  background-color: #007bff; /* Blue background */
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
}

/* Style for the brand on the left */
.navbar-brand {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

/* Hides the brand text if the menu is open */
.hidden-brand {
  display: none;
}

/* The hamburger toggle (bars) */
.navbar-toggle {
  display: none; /* Hidden by default on larger screens */
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Container for the right-hand side navigation items (desktop) */
.navbar-right ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px; /* Spacing between each nav item */
}

/* Style for each navigation item */
.navbar-right li {
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

/* Hover effect for the navigation items */
.navbar-right li:hover {
  background-color: #0056b3; /* Darker blue on hover */
  border-radius: 5px; /* Rounded corners on hover */
}

/* Active state for when a navigation item is clicked */
.navbar-right li:active {
  background-color: #003d80; /* Even darker on active state */
}

/* Ensure text color remains white for all nav items */
.navbar-right li a {
  color: white;
  text-decoration: none;
}

/* For logout link to appear last */
.navbar-right li:last-child {
  margin-left: auto; /* On desktop, push it far right */
}


@media (max-width: 1226px) {
  .navbar-toggle {
      display: flex;
  }    
  .navbar-right {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      width: 250px;
      background-color: #007bff;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
      z-index: 999; /* On top of page content */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 60px; /* Room for top spacing if desired */
      transform: translateX(100%); /* Hide off-screen to the right */
      transition: transform 0.3s ease;
  }

  /* The UL inside .navbar-right can be a simple column stack now */
  .navbar-right ul {
      flex-direction: column;
      width: 100%; /* Make each item full width */
      margin: 0;
      padding: 0;
      display: flex; /* Keep it flex, but stacked */
      gap: 0; /* Remove the horizontal gap for stacked items */
  }

  .navbar-right li {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-right li:hover {
      background-color: #0056b3;
  }

  .navbar-right li:last-child {
      margin-left: 0; /* reset */
      border-bottom: none; /* No extra border on last item */
  }

  /* When .open is applied, slide it in from the right */
  .navbar-right.open {
      transform: translateX(0);
  }
}

.authentication-header {
  margin-left: -200px !important;
}